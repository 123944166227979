@import "../mainVariables";
@import "../mixins";

.about-page-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  min-height: calc(100vh - 80px);

  .section {
    padding: 100px $mainPadding;
    display: flex;
    justify-content: space-between;

    .image-wrapper {
      width: 34%;

      .image-proportion {
        padding-bottom: 94.5%;
        position: relative;
        width: 100%;

        img {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          object-fit: cover;
          height: 100%;
          border-radius: 8px;
        }
      }
    }

    .text-content {
      user-select: none!important;
      width: 60%;
      padding-left: 60px;

      .section-title {
        font-size: 52px;
        font-family: Medium;
        margin-bottom: 30px;
      }

      .section-description {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.4px;
      }
    }

    &.second {
      .text-content {
        user-select: none!important;
        padding-left: 0;
        padding-right: 60px;
      }
    }
  }

  .counter-wrapper {
    padding: 80px $mainPadding;
    width: 100%;
    //transform: translateX(-15%);

    .counter-content {
      box-shadow: 2px 0px 10px 0px rgba(113, 113, 113, 0.14);
      background-color: $brandColor;
      display: flex;
      justify-content: space-between;
      padding: 80px 60px;
      border-radius: 15px;
      color: white;

      .count-item {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .countup {
          //font-weight: 700;
          font-family: Bold;
          font-size: 60px;
          color: white;
          margin-bottom: 16px;
          text-align: center;
        }

        span {
          font-size: 20px;
          font-family: Medium;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          text-align: center;
        }

        &:not(:last-child) {
          padding-right: 20px;
          border-right: 2px solid white;
        }
      }
    }
  }
  .partners-wrapper .partners-title{
    margin: 0 0 100px 0;
  }

  @media screen and (max-width: 1600px) {
    .section {

      .text-content {
        user-select: none!important;
        padding-left: 60px;

        .section-title {
          font-size: 48px;
          margin-bottom: 20px;
        }

        .section-description {
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .section {

      .text-content {
        user-select: none!important;
        padding-left: 40px;

        .section-title {
          font-size: 38px;
          margin-bottom: 20px;
        }

        .section-description {
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .section {
      padding-top: 0;
      flex-direction: column-reverse;

      .image-wrapper {
        width: 100%;
      }

      .text-content {
        user-select: none!important;
        width: 100%;
        padding-left: 0;
        padding-top: 60px;

        .section-title {
          font-size: 38px;
        }

        .section-description {
          font-size: 14px;
          line-height: 26px;
          margin-bottom: 20px;
        }
      }

      &.second {
        flex-direction: column;

        .image-wrapper {
          margin-bottom: 60px;
        }

        .text-content {
          user-select: none!important;
          padding-right: 0;
        }
      }
    }

    .counter-wrapper {
      padding: 40px $mainPadding;

      .counter-content {
        padding: 40px 20px;
        border-radius: 8px;

        .count-item {

          .countup {
            font-size: 36px;
            margin-bottom: 10px;
          }

          span {
            font-size: 12px;
          }

        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .section {
      .text-content {
        user-select: none!important;
        padding-top: 40px;

        .section-title {
          font-size: 28px;
        }

        .section-description {
          font-size: 12px;
          line-height: 24px;
        }
      }

      &.second {
        .image-wrapper {
          margin-bottom: 40px;
        }

        .text-content {
          user-select: none!important;
          padding-right: 0;
        }
      }
    }

    .counter-wrapper {

      .counter-content {

        .count-item {
          width: 32%;
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .counter-wrapper {
      padding: 20px $mainPadding;

      .counter-content {
        padding: 20px 10px;
        border-radius: 8px;

        .count-item {

          .countup {
            font-size: 26px;
            margin-bottom: 10px;
          }

          span {
            font-size: 10px;
          }

          &:not(:last-child) {
            padding-right: 8px;
          }
        }
      }
    }
  }
}
@import "../mainVariables";
@import "../mixins";

.services-page-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  min-height: calc(100vh - 80px);
  background-color: $greyBgColor;

  .services-list {
    padding: 100px $mainPadding;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .service-card {
      width: 49%;
      display: flex;
      margin-bottom: 20px;
      background-color: white;
      border-radius: 15px;
      transition: all 0.2s;
      padding: 30px;

      .item-info {
        width: 60%;

        .inner-part {
          padding-right: 20px;

          .title {
            font-size: 20px;
            color: black;
            font-family: Medium;
            overflow: hidden;
            line-height: 30px;
            height: 56px;
          }

          .line {
            background-color: $brandColor;
            height: 3px;
            width: 40%;
            border-radius: 3px;
            margin: 24px 0;
          }

          .description {
            font-size: 12px;
            line-height: 20px;
            color: #737781;
            overflow: hidden;
            height: 58px;
            font-family: Regular;
            white-space: pre-wrap;
          }
        }
      }

      .image-wrapper {
        width: 40%;
        position: relative;

        .image-proportion {
          padding-bottom: 71.4%;
          position: relative;
          width: 100%;

          img {
            position: absolute;
            bottom: 0;
            //right: 0;
            left: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
            border-radius: $borderRadius;
            z-index: 1;
          }
        }
      }

      &:hover {
        box-shadow: 2px 0px 10px 0px rgba(113, 113, 113, 0.14);
      }
    }

  }


  @media screen and (min-width: 1700px) {
    .services-list {
      .service-card {
        width: 32.5%;
      }
    }
  }

  @media screen and (max-width: 2200px) and (min-width: 1700px) {
    .services-list {
      justify-content: unset;

      .service-card {
        width: 32%;
        margin-right: 1%;

        .item-info {

          .inner-part {

            .title {
              font-size: 16px;
              line-height: 24px;
              height: 46px;
            }

            .line {
              margin: 20px 0;
            }

            .description {
              font-size: 12px;
              line-height: 18px;
              height: 52px;
            }
          }
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .services-list {
      .service-card {
        .item-info {

          .inner-part {

            .title {
              font-size: 16px;
            }

            .line {
              margin: 20px 0;
            }

            .description {
              font-size: 12px;
              line-height: 18px;
              height: 52px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .services-list {
      padding: 60px $mainPadding;

      .service-card {
        padding: 20px;

        .item-info {
          width: 55%;

          .inner-part {
            padding-right: 10px;

            .title {
              font-size: 16px;
              line-height: 26px;
              height: 50px;
            }

            .line {
              margin: 20px 0;
            }

            .description {
              //font-size: 12px;
              //line-height: 18px;
              //height: 34px;
            }
          }
        }

        .image-wrapper {
          width: 45%;
        }
      }

    }
  }

  @media screen and (max-width: 980px) {
    .services-list {

      .service-card {
        width: 100%;
        padding: 30px;

        .item-info {
          width: 60%;

          .inner-part {
            padding-right: 20px;

            .title {
              font-size: 20px;
              line-height: 30px;
              height: 56px;
            }

            .line {
              margin: 36px 0;
            }

            .description {
              font-size: 12px;
              line-height: 18px;
              height: 52px;
            }
          }
        }

        .image-wrapper {
          width: 40%;

          .image-proportion {

          }
        }
      }

    }
  }

  @media screen and (max-width: 700px) {
    .services-list {
      padding: 40px $mainPadding;

      .service-card {
        padding: 20px 10px;

        .item-info {
          padding-right: 10px;

          .inner-part {

            .title {
              font-size: 16px;
              line-height: 24px;
              height: 46px;
            }

            .line {
              margin: 26px 0;
            }

            //.description {
            //  font-size: 12px;
            //  line-height: 18px;
            //  height: 58px;
            //}
          }
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .services-list {
      .service-card {
        flex-direction: column-reverse;
        background-color: white;
        justify-content: center;
        align-items: center;
        //padding: 30px;
        border-radius: 15px;
        margin-bottom: 20px;

        .image-wrapper {
          width: calc(100% - 20px);

          .image-proportion {

            img {
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        .item-info {
          padding: 0 20px 0 20px;
          flex-direction: column;
          position: unset;
          box-shadow: unset;
          background-color: unset;
          margin-top: 50px;
          width: 100%;

          .inner-part {
            width: 100%;
            padding-left: 0;
            text-align: center;
            transform: translateY(-30px);


            .title {
              font-size: 18px;
              max-height: 46px;
              height: unset;
            }

            .line {
              margin: 26px auto;
            }

            .description {
              font-size: 13px;
              line-height: 20px;
              height: 58px;
            }
          }

          /* &:hover {
             background-color: unset;

             .inner-part {

               .position {
                 color: $brandColor;
               }

               .title {
                 color: unset;
               }

               .line {
                 background-color: $brandColor;
               }

               .description {
                 color: #737781;
               }
             }
           }*/
        }
      }
    }
  }
}
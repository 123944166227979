@import "../mainVariables";
@import "../mixins";

.slider-wrapper {
  width: 100%;

  .image-wrapper {
    width: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;

    .slick-slider {
      height: 100%;

      .slider-item {
        position: relative;
        width: 100%;
        padding-top: 48%;


        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .slider-content {
          position: absolute;
          left: $mainPadding;
          width: 45%;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%);

          p {
            font-size: 20px;
            color: $brandColor;
            font-family: Medium;
            height: 27px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .title {
            color: white;
            font-size: 58px;
            line-height: 86px;
            font-family: Medium;
            margin: 20px 0;
            height: 172px;
            overflow: hidden;
          }

          .description {
            color: white;
            font-size: 18px;
            line-height: 32px;
            height: 96px;
            overflow: hidden;
          }

          .contact-btn {
            width: 180px;
            height: 55px;
            border-radius: 50px;
            background-color: $brandColor;
            color: white;
            @include flex-center;
            font-size: 16px;
            font-family: Medium;
            margin: 60px 0;
            transition: all 0.2s;

            &:hover {
              color: $brandColor;
              background-color: white;

              svg {
                poligon {
                  fill: white;
                }
              }
            }
          }
        }
      }

      .slick-dots {
        position: absolute;
        left: $mainPadding;
        bottom: 50px;
        width: 300px;
        display: flex !important;

        li {
          button {
            &::before {
              font-family: 'slick';
              font-size: 6px;
              line-height: 20px;
              top: 0;
              left: 0;
              width: 20px;
              height: 4px;
              content: '';
              background: white;
              opacity: 1;
              border-radius: 2px;
            }
          }

          &:hover {
            button::before {
              background: $brandColor;
            }
          }
        }

        .slick-active {
          button::before {
            background: $brandColor;
          }
        }
      }

    }
  }

  @media all and (max-width: 1400px) {
    .image-wrapper {

      .slick-slider {

        .slider-item {
          .slider-content {
            width: 52%;

            p {
              font-size: 18px;
              height: 24px;
            }

            .title {
              font-size: 52px;
              line-height: 70px;
              margin: 20px 0;
              height: 140px;
            }

            .description {
              font-size: 16px;
              line-height: 28px;
              height: 82px;
            }

            .contact-btn {
              width: 160px;
              height: 46px;
              font-size: 14px;
              margin: 40px 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .image-wrapper {

      .slick-slider {

        .slider-item {
          .slider-content {
            width: 52%;

            p {
              font-size: 16px;
              height: 24px;
              margin-bottom: 0;
            }

            .title {
              font-size: 40px;
              line-height: 50px;
              margin: 10px 0;
              height: 100px;
            }

            .description {
              font-size: 14px;
              line-height: 24px;
              height: 70px;
            }

            .contact-btn {
              width: 140px;
              height: 40px;
              font-size: 12px;
              margin: 30px 0;
            }
          }
        }

        .slick-dots {
          bottom: 20px;
        }
      }

      .arrows {
        bottom: 20px;
      }
    }
  }


  @media all and (max-width: 700px) {
    .image-wrapper {
      .slick-slider {

        .slider-item {
          padding-top: 133%;

          .slider-content {
            width: 70%;
            top: 30%;

            p {
              font-size: 14px;
              height: 18px;
            }

            .title {
              font-size: 22px;
              line-height: 32px;
              margin: 10px 0;
              height: 62px;
            }

            .description {
              font-size: 12px;
              line-height: 20px;
              height: 58px;
            }

            .contact-btn {
              width: 120px;
              height: 32px;
              font-size: 11px;
              margin: 20px 0;
            }
          }
        }

        .slick-dots {
          bottom: 10px;
        }
      }
    }
  }

  @media all and (max-width: 500px) {
    .image-wrapper {

      .slick-slider {

        .slider-item {
          .slider-content {
            width: 80%;
            padding: 0 $mainPadding;

            p {
              font-size: 12px;
              height: 16px;
            }

            .title {
              font-size: 18px;
              line-height: 24px;
              margin: 6px 0;
              height: 48px;
            }
          }
        }
      }
    }
  }
}

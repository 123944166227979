@import "../mainVariables";
@import "../mixins";

.right-menu-wrapper {
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 9901;
  width: 100%;
  height: 100vh;
  overflow: auto;
  transition: all 0.35s cubic-bezier(.39, .575, .565, 1);
  visibility: hidden;
  opacity: 0;


  @media all and (max-width: 1000px) {

  }

  &.expand_on {
    visibility: visible;
    opacity: 1;
  }

  .right-menu-content {
    width: 450px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 20px;
    z-index: 9002;
    height: 100%;
    background-color: white;
    overflow-y: auto;


    &::-webkit-scrollbar {
      width: 0;
    }

    .ant-dropdown-trigger {
      margin-top: 10px;
      color: $brandColor;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .arrow-icon {
        width: 14px;
        height: 14px;
        transform: rotate(90deg);
        margin: 0 30px 0 8px;
      }
    }

    .ant-menu-root > .ant-menu-item {
      border-bottom: 2px solid $greyBorderColor;
      position: relative;

      .badge {
        position: absolute;
        bottom: 10px;
        right: 0;
      }
    }

    .ant-menu-root > .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding-left: 0 !important;
        border-bottom: 2px solid $greyBorderColor;

        &:hover {
          //color: $greyColor;
        }
      }

      .ant-menu {
        .ant-menu-submenu-title {
          border-bottom: 0;
        }
      }
    }

    .ant-menu-item:active, .ant-menu-submenu-title:active {
      background-color: white;
    }

    .ant-menu {
      width: 100%;
      margin-top: 10px;

      .ant-menu-item {
        background-color: transparent;
        color: $lightGreyColor;
        border-right: 0;
        padding-left: 0 !important;
        display: flex;
        justify-content: space-between;

        a {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .category-item-img {
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }

        a {
          width: 100%;
          height: 100%;
        }

        &::after {
          border-right: 0 !important;
        }
      }
    }

    .ant-menu-inline {
      border-right: 0;
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        height: 45px;

        &:hover {
          //color: $greyColor;
        }
      }
    }

    .mobile-nav{
      display: none;
    }
    .mobile-nav, .desktop-nav {
      width: 100%;
      flex-direction: column;

      .nav-item {
        font-size: 16px;
        cursor: pointer;
        font-family: Medium;
        padding: 8px 10px;

        &:hover {
          color: $brandColor;
        }
      }

    }

      @media all and (max-width: 1050px) {
        width: 100%;
        .mobile-nav {
          display: flex;
        }
      }
  }

  .mobile-menu-overlay {
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9000;
    opacity: 0.55;
  }


  & {
    .hide {
      display: none;
    }
  }


}

.ant-dropdown {
  z-index: 10003;
}


@media all and (max-width: 600px) {
  .right-menu-wrapper {
    padding-top: 60px;

    .right-menu-content {
      padding: 0 $mainPadding $mainPadding;
      width: 100%;

      .search_box {
        height: 36px;
        padding: 5px;

        .input-group-append {
          .search_btn {
            padding-right: 5px;

            svg {
              width: 20px;
            }
          }
        }

        .search {
          font-size: 13px;
        }
      }

      .ant-menu {
        margin-top: 5px;
      }
    }
  }
}
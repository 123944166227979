@import "../mainVariables";
@import "../mixins";

.contacts-page-wrapper {
  min-height: calc(100vh - 80px);
  background-color: $greyBgColor;
  padding-bottom: 80px;

  .contact-card-list {
    display: flex;
    justify-content: space-between;
    padding: 0 $mainPadding;
    transform: translateY(-50%);

    .contact-item {
      -webkit-user-select:none!important;
      user-select: none!important;
      width: 23.5%;
      display: flex;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      padding: 40px;
      transition: all 0.2s;

      .icon-wrapper {
        background-color: #F3F4F6;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        @include flex-center;
      }

      .contact-item-map {
        cursor: pointer;
      }

      .item-content {
        padding-left: 30px;
        width: calc(100% - 70px);

        .title {
          -webkit-user-select:none!important;
          user-select: none!important;
          font-family: Medium;
          font-size: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 10px;
          color: $darkTextColor;
        }

        .value {
          font-size: 14px;
          //white-space: nowrap;
          //text-overflow: ellipsis;
          overflow: hidden;
          color: #828A99;
        }
      }

      &:hover {
        background-color: $brandColor;

        .icon-wrapper {
          background-color: white;
        }

        .item-content {

          .title {
            color: white;
          }

          .value {
            color: white;
          }

        }
      }
    }
  }

  .contact-content {
    display: flex;
    justify-content: space-between;
    padding: 0 $mainPadding;

    .info-content {
      width: 44%;

      .locations-wrapper {
        min-height: 300px;

        .map {
          height: 340px;
        }
      }

      .contact-title {
        -webkit-user-select:none!important;
        user-select: none!important;
        font-family: Medium;
        font-size: 32px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 20px;
      }

      .description {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .send-message-wrapper {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .contact-title {
        -webkit-user-select:none!important;
        user-select: none!important;
        width: 100%;
        font-family: Medium;
        font-size: 32px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 35px;
      }
      .custom-input-group {
        width: 48%;

        label {
          position: absolute;
          bottom: 0;
        }

        input {
          font-size: 16px;
        }
      }

      .message {
        width: 100%;

        .custom-input-group {
          width: 100%;
        }
      }


      .link-button {
        margin-left: auto;
        //width: 100%;
        //font-size: 16px;
        .anticon-loading {
          margin-left: 10px;
        }

        .tick-icon {
          width: 0;
          height: 14px;
          transition: all 0.2s;
          visibility: visible;

          path {
            fill: white;
          }
        }

        .show-tick {
          width: 14px;
          visibility: visible;
          opacity: 1;
          margin-left: 10px;
        }

        .hide-tick {
          width: 0;
          visibility: hidden;
          opacity: 0;
          //width: 0;
        }

        &:hover {
          box-shadow: 2px 0px 10px 0px rgba(113, 113, 113, 0.14);

          .tick-icon {
            path {
              fill: $brandColor;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1600px) {
    .contact-card-list {

      .contact-item {
        padding: 30px;

        .icon-wrapper {
          width: 60px;
          height: 60px;
        }

        .item-content {
          padding-left: 20px;
          width: calc(100% - 60px);

          .title {
            font-size: 18px;
          }

          .value {
            font-size: 14px;
          }
        }
      }
    }

    .contact-content {
      .info-content {
        width: 44%;

        .contact-title {
          font-size: 28px;
          margin-bottom: 20px;
        }

        .description {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  @media all and (max-width: 1400px) {
    .contact-card-list {

      .contact-item {
        padding: 30px;

        .icon-wrapper {
          width: 50px;
          height: 50px;
        }

        .item-content {
          padding-left: 20px;
          width: calc(100% - 50px);

          .title {
            font-size: 16px;
          }

          .value {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    .contact-card-list {

      .contact-item {
        width: 24%;
        padding: 20px;
        border-radius: 8px;

        .icon-wrapper {
          width: 40px;
          height: 40px;
        }

        .item-content {
          padding-left: 12px;
          width: calc(100% - 40px);

          .title {
            font-size: 14px;
            margin-bottom: 5px;
          }

          .value {
            font-size: 11px;
          }
        }
      }
    }

    .contact-content {
      flex-direction: column;

      .info-content {
        width: 100%;
        margin-bottom: 20px;
      }

      .send-message-wrapper {
        width: 100%;
      }
    }
  }

  @media all and (max-width: 850px) {
    .contact-card-list {
      flex-wrap: wrap;
      transform: translateY(calc(-25% + 10px));

      .contact-item {
        width: 49%;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 20px;

        .icon-wrapper {
          width: 40px;
          height: 40px;
        }

        .item-content {
          padding-left: 12px;
          width: calc(100% - 40px);
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    padding-bottom: 40px;
    .contacts-page-start {
      .image-proportion {
        .content {
          top: 35%;
        }
      }
    }

    .contact-card-list {
      transform: translateY(calc(-75% + 10px));

      .contact-item {
        padding: 16px 12px;
        margin-bottom: 20px;

        .icon-wrapper {
          width: 32px;
          height: 32px;

          svg {
            height: 18px;
          }
        }

        .item-content {
          padding-left: 8px;
          width: calc(100% - 32px);

          .title {
            font-size: 13px;
            margin-bottom: 5px;
          }

          .value {
            font-size: 10px;
          }
        }
      }
    }

    .contact-content {
      margin-top: -70px;

      .info-content {
        margin-bottom: 20px;

        .contact-title {
          font-size: 22px;
          margin-bottom: 12px;
        }

        .description {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .send-message-wrapper {
        .contact-title {
          font-size: 22px;
          margin-bottom: 12px;
        }
        .custom-input-group {
          width: 100%;

          input {
            font-size: 14px;
          }

          input::placeholder, textarea::placeholder {
            font-size: 12px;
          }
        }
      }
    }
  }
}

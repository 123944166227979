@import "../mainVariables";
@import "../mixins";

.work-page-wrapper {
  -webkit-user-select: none !important;
  user-select: none !important;
  width: 100%;
  margin: 0 0 100px 0;

  .work-list {
    width: calc(100% - 20px);
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 80px $mainPadding;

    .work-card {
      width: calc(25% - 20px);
      position: relative;
      margin-bottom: 20px;
      margin-right: 20px;

      .card-proportion {
        padding-bottom: 145%;
        position: relative;
        width: 100%;

        img {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          object-fit: cover;
          height: 100%;
          border-radius: $borderRadius;
        }
      }

      .work-title {
        position: absolute;
        bottom: 0;
        color: white;
        font-size: 18px;
        height: 108px;
        overflow: hidden;
        //font-weight: 600;
        font-family: Medium;
        z-index: 1;
        padding: 30px;
        width: 100%;
        text-align: center;
        border-radius: 0 0 $borderRadius $borderRadius;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  @media all and (max-width: 1050px) {
    .work-list {
      width: calc(100% - 20px);
      .work-card {
        width: calc(100% / 3 - 20px);

        &:nth-child(4n) {
          margin-right: 20px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  @media all and (max-width: 800px) {
    .work-list {
      .work-card {
        width: calc(100% / 3 - 10px);
        position: relative;
        margin-bottom: 10px;
        margin-right: 10px;

        .work-title {
          font-size: 16px;
          height: 90px;
          padding: 20px;
          line-height: 26px;
        }
        &:nth-child(4n) {
          margin-right: 10px;
        }
      }
    }
  }

  @media all and (max-width: 600px) {
    .work-list {
      .work-card {
        .work-title {
          font-size: 13px;
          height: 60px;
          padding: 10px;
          line-height: 20px;
        }
      }
    }
  }

  @media all and (max-width: 500px) {
    .work-list {
      .work-card {
        width: calc(50% - 20px);

        &:nth-child(3n) {
          margin-right: 10px;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  @media all and (max-width: 370px) {
    .work-list {
      .work-card {
        width: 100%;
        margin-right: 0;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

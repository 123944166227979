@import "../fonts";
@import "../mainVariables";

.footer-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  background-color: #20454C;
  color: white;
  .language-dropdown-item{
    font-family: Medium;
  }

  .selected-language{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;

    div{
      margin-left: 10px;
      text-transform: uppercase;
      font-family: Medium;
      color: white;
      display: flex;
      align-items: center;
      letter-spacing: 1.6px;
      height: 16px;
    }
    img{
      width: 24px;
      height: 16px;
      object-fit: cover;
      border-radius: 2px;
    }
    .icon-language{

    }
  }
  .desktop-footer-wrapper {
    .top-part{
      .col{
        .logo-wrapper{
          img{
            width: 97px;
          }
        }
      }
    }
  }

  .mobile-footer-wrapper {
    display: none;
    padding: 20px $mainPadding;

    .top-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      padding: 10px 0;

      .logo-wrapper {
        height: 40px;

        img {
          width: 46%;
        }
      }

      .icons-wrapper {
        display: flex;
        align-items: center;
        height: 25px;

        a {
          margin-left: 6px;

          .social-icon {
            width: 25px;
            height: 25px;
          }

          .facebook_icon {
            :hover{
              fill: #4292FF;
            }
          }
    
          .linkedin_icon {
            :hover{
              fill: #0A66C2;
            }
          }
        }

        .selected-language{
          margin-top: 0;
        }
      }
    }

    .ant-collapse {
      border: 0;
      background-color: #20454C;

      .ant-collapse-item {
        border-color: $borderColor;
        border-bottom: 0;
      }

      .ant-collapse-header {
        background-color: #20454C;
        border-radius: 0;
        padding: 12px 0;
        font-size: 14px;
        color: white;

        .ant-collapse-arrow {
          position: absolute;
          right: $mainPadding;
          transform: rotate(90deg);
          //svg{
          polygon {
            fill: white;
          }

          //}
        }
      }

      .ant-collapse-content {
        background-color: #20454C;
        border-top: 0;
        border-radius: 0;

        .link-item {
          padding: 10px 0;
          font-size: 12px;
          color: white;
        }

        .ant-collapse-content-box {
          padding: 0 10px;
          color: white;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          a{
            padding: 10px 0;
          }
        }
      }
    }

    .bottom-side {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .developed-by {
        display: flex;
        align-items: center;
        font-size: 12px;

        a {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
      }

      .copyright {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }

  }


  .top-part, .bottom-part {
    display: flex;
    justify-content: space-between;
    padding: 60px $mainPadding;

  }

  .bottom-part {
    border-top: 1px solid #313234;
    padding: 30px $mainPadding;

    .copyright {
      font-size: 13px;
      color: #D2D2D3;
    }

    .developed-by {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #D2D2D3;

      a {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        margin-left: 10px;
      }


      svg {
        margin-left: 10px;
      }
    }
  }

  .col {
    color: white;

    .logo-wrapper {
      margin-bottom: 35px;
    }


    .title {
      color: white;
      font-family: Medium;
      margin-bottom: 24px;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      display: flex;
      align-items: center;
      color: white;


      &:hover, p:hover {
        color: #D0AF4D;
        //font-family: Medium;
      }
    }


    p, a {
      color: white;
      font-size: 13px;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .icons-wrapper {
    display: flex;

    a {
      .social-icon {
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }

      .facebook_icon {
        :hover{
          fill: #4292FF;
        }
      }

      .linkedin_icon {
        :hover{
          fill: #0A66C2;
        }
      }
    }

   

   
       
  }

 

  

  @media all and (max-width: 1400px) {
    .col {
      .title {
        font-size: 18px;
      }

      p {
        font-size: 15px;
        margin-bottom: 5px;
      }
    }
  }

  @media all and (max-width: 1200px) {
    .col {
      width: 140px;

      .title {
        font-size: 16px;
      }

      p {
        font-size: 13px;
        margin-bottom: 3px;
      }
    }
  }

  @media all and (max-width: 1000px) {
    flex-wrap: wrap;
    .desktop-footer-wrapper {
      display: none;
    }
    .mobile-footer-wrapper {
      display: block;
    }

    .col {
      order: 1;

      .copyright {
        font-size: 13px;
      }

      .developed-by {
        font-size: 12px;
        display: flex;
        align-items: center;

        svg {
          width: 90px;
          height: 22px;
        }
      }

      &:first-child {
        order: 2;
        width: 60%;
        margin-top: 30px;
      }

      &:last-child {
        width: 40%;
        order: 3;
        margin-top: 30px;
      }

      width: 150px;
    }
  }

  @media all and (max-width: 550px) {
    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 1;
      width: 100%;

      &:first-child {
        order: 3;
        width: 100%;
        margin-top: 20px;
      }

      &:last-child {
        width: 100%;
        order: 2;
        margin-top: 20px;
      }
    }
  }
}
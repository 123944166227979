@import "../mainVariables";
@import "../mixins";

.description-wrapper {
  width: 100%;
  padding: 0 $mainPadding;
  margin-bottom: 100px;
  position: relative;

  .description-content {
    width: 70%;
    background-color: #06191D;
    border-radius: 15px;
    padding: 100px 80px;

    .description-proportion {
      width: 56%;
      height: 100%;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .inner-part {
        //position: absolute;
      }

      p {
        font-size: 20px;
        font-family: Medium;
        color: $brandColor;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        //font-weight: 600;
        height: 28px;
        margin-bottom: 16px;
      }

      .title {
        font-size: 42px;
        font-family: Bold;
        overflow: hidden;
        height: 50px;
        line-height: 50px;
        margin-bottom: 28px;
      }

      .text {
        font-size: 18px;
        margin-bottom: 60px;
        overflow: hidden;
        height: 76px;
        line-height: 26px;
      }

      .about-us {
        width: 160px;
        height: 50px;
        border-radius: 50px;
        background-color: white;
        color: #06191D;
        @include flex-center;
        font-family: Medium;
        transition: all 0.2s;
        &:hover{
          background-color: $brandColor;
          color: white;
        }
      }
    }
  }

  .description-list-wrapper {
    width: 48%;
    position: absolute;
    transform: translate(14px, -48%);
    right: $mainPadding;
    top: 50%;

    .slick-slider {
      .slick-list {
        .slick-track {
          margin-left: 0;
          padding: 5px 0;
        }
      }
    }

    .description-item {
      width: 100%;
      color: white;
      padding-right: 14px;
      transition: all 0.2s;

      .description-item-content {
        padding: 50px 30px;
        background-color: $brandColor;
        border-radius: $borderRadius;
        cursor: pointer;
        .index {
          margin-bottom: 10px;
          font-size: 18px;
        }

        .item-title {
          font-size: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          height: 24px;
          font-family: Medium;
        }

        .line {
          width: 50%;
          height: 2px;
          background-color: white;
          border-radius: 3px;
          display: block;
          margin: 40px 0;
          transition: all 0.2s;
        }

        .item-description {
          line-height: 24px;
          height: 92px;
          overflow: hidden;
          font-size: 13px;
        }

      }

      &:hover {
        color: black;

        .description-item-content {
          box-shadow: 0 2px 4px 0 rgba(47, 51, 56, 0.16), inset 0 0 0 15px #fff;
          background-color: white;

          .index {
            color: $brandColor;
          }

          .line {
            background-color: $brandColor;
          }
        }
      }

      img {
        //position: absolute;
        //top: 0;
        //left: 0;
        //width: 100%;
        height: 36px;
        //object-fit: contain;
        //border-radius: 4px;
      }
    }

    .mobile-arrows {
      display: none;
    }
  }

  .arrows {
    bottom: 0;

    .next-btn, .prev-btn {
      transform: unset;
    }
  }

  @media all and (max-width: 1500px) {
    .description-content {
      width: 70%;
      border-radius: 15px;
      padding: 70px 40px;

      .description-proportion {
        .title {
          font-size: 38px;
          height: 40px;
          line-height: 40px;
        }
        .text{
          font-size: 14px;
          height: 72px;
        }
      }
    }
    .description-list-wrapper {
      width: 50%;
      transform: translate(20px, -50%);

      .description-item {

        .description-item-content {
          padding: 30px 20px;

          .index {
            font-size: 16px;
          }

          .line {
            //margin: 30px 0;
          }

          .item-title {
            font-size: 16px;
            height: 24px;
          }

          .item-description {
            line-height: 22px;
            height: 86px;
            font-size: 13px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1300px) {
    .description-content {
      width: 70%;
      border-radius: 15px;
      padding: 60px 40px;

      .description-proportion {
        .title {
          font-size: 34px;
          height: 36px;
          line-height: 36px;
        }
        .text{
          //font-size: 14px;
          //height: 72px;
        }
        .about-us {
          width: 140px;
          height: 44px;
          border-radius: 50px;
        }
      }
    }
    .description-list-wrapper {
      width: 56%;
      transform: translate(20px, -50%);

      .description-item {
        //padding-right: 10px;

        .description-item-content {
          //padding: 40px 20px;

          //.index {
          //  font-size: 16px;
          //}
          //
          //.item-title {
          //  font-size: 16px;
          //  height: 24px;
          //}
          //
          //.line {
          //  margin: 34px 0;
          //}
          //
          //.item-description {
          //  line-height: 22px;
          //  height: 42px;
          //  font-size: 13px;
          //}
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    //padding-bottom: 100px;
    padding: 0;
    margin-bottom: 0;

    .description-content {
      width: 100%;
      padding: 100px $mainPadding 200px $mainPadding;
      border-radius: 0;

      .description-proportion {
        width: 100%;

        p {
          font-size: 16px;
          height: 22px;
        }

        .title {
          font-size: 32px;
          height: 38px;
          line-height: 38px;
          margin-bottom: 28px;
        }

        .text {
          font-size: 16px;
          margin-bottom: 30px;
          height: 76px;
          line-height: 26px;
        }

      }
    }

    .description-list-wrapper {
      width: 100%;
      padding: 0 0 0 $mainPadding;
      position: unset;
      top: unset;
      transform: translateY(-50%);
      margin: auto;

      .mobile-arrows {
        display: flex;
        bottom: unset;
        top: -115px;
        //transform: translateY(-50%);
      }

      .description-item {

        .description-item-content {

        }
      }
    }
    .desktop-arrows {
      display: none;
    }
  }

  @media all and (max-width: 600px) {
    margin-bottom: 0;
    .description-content {
      width: 100%;
      padding: 60px $mainPadding 160px $mainPadding;

      .description-proportion {

        p {
          font-size: 14px;
          height: 18px;
        }

        .title {
          font-size: 28px;
          height: 34px;
          line-height: 34px;
          margin-bottom: 18px;
        }

        .text {
          font-size: 14px;
          margin-bottom: 30px;
          height: 64px;
          line-height: 22px;
        }

        .about-us {
          width: 140px;
          height: 38px;
          border-radius: 50px;
          font-size: 12px;
        }
      }
    }
    .description-list-wrapper {
      .mobile-arrows {
        top: -75px;
        .prev-btn, .next-btn {
          width: 38px;
          height: 38px;

          svg {
            width: unset;
          }
        }
      }
    }
  }
}
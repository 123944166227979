@import "../mainVariables";
@import "../mixins";

.header-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  padding: 0 $mainPadding;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a{
    .ask-logo{
      svg{
        width: 150px;
      }
    }
  }

  .nav-menu {
    display: flex;
    align-items: center;
    padding-right: 88px;

    .nav-item {
      font-size: 16px;
      margin-left: 40px;
      cursor: pointer;
      font-family: Medium;

      &:hover {
        color: $brandColor;
      }
    }

    .header_burger {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: $mainPadding;
      width: 38px;
      height: 38px;
      -webkit-transition: -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
      transition: -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
      transition: transform .5s cubic-bezier(.785, .135, .15, .86);
      transition: transform .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
      cursor: pointer;

      &:hover {
        -webkit-transition: color .5s cubic-bezier(.785, .135, .15, .86), border .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), opacity .5s cubic-bezier(.785, .135, .15, .86);
        transition: color .5s cubic-bezier(.785, .135, .15, .86), border .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), opacity .5s cubic-bezier(.785, .135, .15, .86);
      }

      @media screen and (max-width:1050px) {
        display: block;
      }
    }

    .header_burger::after, .header_burger::before {
      content: '';
      position: absolute;
      left: 0;
      width: 38px;
      height: 5px;
      background: black;
      -webkit-transition: width .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
      transition: width .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
      transition: width .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), transform .5s cubic-bezier(.785, .135, .15, .86);
      transition: width .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), transform .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
    }

    .header_burger:hover::after {
      width: 38px;
      -webkit-transition: width .25s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
      transition: width .25s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
      transition: width .25s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), transform .5s cubic-bezier(.785, .135, .15, .86);
      transition: width .25s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), transform .5s cubic-bezier(.785, .135, .15, .86), -webkit-transform .5s cubic-bezier(.785, .135, .15, .86);
    }

    .header_burger::before {
      top: 12px;
    }

    .header_burger::after {
      top: 24px;
      width: 26.6px;
    }

    a:hover {
      -webkit-transition: color .5s cubic-bezier(.785, .135, .15, .86), border .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), opacity .5s cubic-bezier(.785, .135, .15, .86);
      transition: color .5s cubic-bezier(.785, .135, .15, .86), border .5s cubic-bezier(.785, .135, .15, .86), background .5s cubic-bezier(.785, .135, .15, .86), opacity .5s cubic-bezier(.785, .135, .15, .86);
    }

    .close-menu {
      &:before {
        -webkit-transform: rotate(45deg) translate3d(0, 8.5px, 0);
        transform: rotate(45deg) translate3d(0, 8.5px, 0);
      }
      &:after{
        width: 38px ;
        -webkit-transform: rotate(-45deg) translate3d(0,-8.5px,0);
        transform: rotate(-45deg) translate3d(0,-8.5px,0);
      }
    }
  }
  div{
    position: relative !important;
  }

  @media all and (max-width: 1300px) {
    .nav-menu {
      .nav-item {
        font-size: 16px;
      }
    }
  }

  @media all and (max-width: 1050px) {
    .nav-menu {
      .nav-item {
        display: none;
      }
    }
  }
}
@import "../mainVariables";
@import "../mixins";

.partners-wrapper {
  width: 100%;
  padding: 0 $mainPadding 100px $mainPadding;

  .partners-title {
    font-size: 42px;
    font-family: Medium;
    //font-weight: 600;
    color: black;
    margin: 100px 0;
    text-align: center;
  }


  .partners-list-wrapper {
    position: relative;

    .slick-slider {

      //padding-right: 10px;
      .slick-list {
        .slick-track {
          margin-left: 0;
          padding-top: 5px;
        }
      }
    }

    .slick-slide .slick-active {
      width: 300px;
    }

    .partner-item {
      width: 86%;
      height: 100%;
      //padding-right: 20px;
      position: relative;

      .partner-content {
        padding-bottom: 40%;
        position: relative;
      }

      img {
        width: 100%;
        height: 100%;
        margin: auto;
        //object-fit: cover;
        transition: all 0.3s;
        position: absolute;
        top: 0;
        left: 0;
      }

      .fade-image {
        z-index: 1;
        opacity: 1;
      }

      .main-image {
        z-index: 0;
        opacity: 0;
      }

      &:hover {

        .fade-image {
          z-index: 0;
          opacity: 0;
        }

        .main-image {
          z-index: 1;
          opacity: 1;
        }
      }
    }

    .top_image {
      margin-bottom: 10%;
    }

    .work-item {
      width: 86%;
      height: 100%;
      //padding-right: 20px;
      position: relative;

      .work-content {
        height: 250px;
        padding-bottom: 40%;
        position: relative;

        .work-title3 {
          position: absolute;
          bottom: 0;
          color: white;
          font-size: 18px;
          //height: 108px;
          overflow: hidden;
          //font-weight: 600;
          font-family: Medium;
          z-index: 1;
          padding: 30px 0px;
          width: 100%;
          text-align: center;
          border-radius: 0 0 $borderRadius $borderRadius;
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%
          );
        }

      }

      img {
        width: 100%;
        height: 100%;
        margin: auto;
        //object-fit: cover;
        transition: all 0.3s;
        position: absolute;
        top: 0;
        left: 0;
      }

      .fade-image {
        z-index: 0;
        opacity: 0;
      }

      .main-image {
        z-index: 1;
        opacity: 1;
      }

      &:hover {

        .fade-image {
          z-index: 0;
          opacity: 0;
        }

        .main-image {
          z-index: 1;
          opacity: 1;
        }
      }
    }
    }

    @media all and (max-width: 1050px) {
      padding-right: 0;

      .partners-title {
        font-size: 28px;
        margin: 80px 0;
      }

      .next-btn,
      .prev-btn {
        display: none;
      }
    }

    @media all and (max-width: 700px) {
      .partners-title {
        font-size: 22px;
        margin: 60px 0;
      }

      .partners-list-wrapper {
        .partner-item {}
      }
    }
  }
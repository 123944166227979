@import "../mainVariables";
@import "../mixins";

.about-wrapper {
  padding: 0 $mainPadding 100px $mainPadding;
  display: flex;
  justify-content: space-between;

  .image-wrapper {
    width: 50%;

    .image-proportion {
      padding-bottom: 94.5%;
      position: relative;
      width: 100%;

      img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 8px;
      }
    }
  }

  .about-content {
    width: 50%;
    padding-left: 80px;
    position: relative;

    .title {
      font-size: 52px;
      //font-weight: 600;
      font-family: Medium;
      margin-bottom: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .description {
      line-height: 30px;
      font-size: 18px;
      margin-bottom: 30px;
      letter-spacing: 0.3px;
      overflow: hidden;
      height: 238px;
    }

    a {
      font-size: 18px;
      font-family: Medium;
      color: $brandColor;
      cursor: pointer;
      margin-bottom: 40px;

      width: 100%;
      display: flex;
      align-items: center;

      span{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
      svg{
        margin-left: 10px;
        polygon{
          fill:$brandColor!important;
        }
      }
    }

    .counter-wrapper {
      width: 115%;
      box-shadow: 2px 0 10px 0 rgba(113, 113, 113, 0.14);
      background-color: white;
      display: flex;
      justify-content: space-between;
      padding: 60px;
      border-radius: $borderRadius;
      position: absolute;
      bottom: 40px;
      right: 0;

      .count-item {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .countup {
          font-family: Bold;
          font-size: 50px;
          color: $brandColor;
          margin-bottom: 10px;
          text-align: center;
        }

        span {
          font-size: 16px;
          font-family: Medium;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          text-align: center;
        }

        &:not(:last-child) {
          padding-right: 20px;
          border-right: 1px solid $greyBorderColor;
        }
      }
    }
  }

  @media screen and (max-width: 1700px) {
    .about-content {
      padding-left: 60px;

      .title {
        font-size: 48px;
        margin-bottom: 20px;
      }

      .description {
        line-height: 26px;
        font-size: 16px;
        height: 206px;
      }

      a {
        font-size: 16px;
        margin-bottom: 30px;
        width: 100%;
      }

      .counter-wrapper {
        padding: 50px;
        bottom: 40px;

        .count-item {

          .countup {
            font-weight: 700;
            font-size: 46px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .about-content {
      padding-left: 40px;

      .title {
        font-size: 38px;
        margin-bottom: 20px;
      }

      .description {
        line-height: 22px;
        font-size: 14px;
        height: 174px;
      }

      a {
        font-size: 14px;
        margin-bottom: 20px;
      }

      .counter-wrapper {
        padding: 40px;
        bottom: 30px;

        .count-item {

          span {
            //font-size: 14px;
          }

          .countup {
            font-weight: 700;
            font-size: 38px;
          }
        }
      }
    }


  }

  @media screen and (max-width: 1150px) {
    .about-content {
      .counter-wrapper {
        padding: 30px;
        bottom: 20px;

        .count-item {

          span {
            font-size: 14px;
          }

          .countup {
            font-weight: 700;
            font-size: 34px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    flex-direction: column-reverse;
    position: relative;

    .image-wrapper {
      width: 100%;
      padding-bottom: 155px;
    }

    .about-content {
      width: 100%;
      position: unset;
      padding-left: 0;

      .counter-wrapper {
        width: calc(100% - 2 * #{$mainPadding});
        padding: 30px;
        bottom: 80px;
        right: $mainPadding;

        .count-item {

          span {
            font-size: 14px;
          }

          .countup {
            font-weight: 700;
            font-size: 34px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 60px;
    .about-content {

      .title {
        font-size: 22px;
        margin-bottom: 16px;
      }

      .description {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px;
        height: 126px;
      }

      .counter-wrapper {
        padding: 20px;

        .count-item {

          span {
            font-size: 11px;
          }

          .countup {
            font-size: 24px;
          }
        }
      }
    }
  }
}
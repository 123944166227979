@import "../../mainVariables";

.custom-input-group {
  margin-bottom: 16px;
  position: relative;

  label {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #677897;
    margin-bottom: 8px;

    &.invalid {
      color: #FF0000;
    }
  }

  .ant-select-selection-item {
    box-shadow: unset;
  }

  input, textarea, select {
    width: 100%;
    height: 52px;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid $greyBorderColor;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 400;
    color: $darkTextColor;
    font-family: Medium;
    overflow: hidden;

    &:not(.ant-select-search__field):focus {
      border-bottom: 2px solid $brandColor;
      outline: none;
    }

    &:not(.ant-select-search__field):focus + label {
      color: $brandColor;
    }

    &.resizable {
      resize: vertical;
      min-height: 100px;
    }

    &.invalid {
      border-color: #FF0000 !important;
      outline: none;
    }

    &:disabled {
      background: white !important;
    }
  }

  input[type="checkbox"] {
    height: 25px;
    width: 25px;
  }

  input::placeholder, textarea::placeholder {
    color: #828A99;
    font-size: 14px;
    font-family: Regular;
  }

  textarea {
    resize: none;
  }

  .custom-select {
    position: relative;

    .ant-select-selection--multiple {
      min-height: 50px;

      .ant-select-selection__rendered {
        min-height: 50px;
        display: flex;
        align-items: center;

        li {
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }
    }

    .ant-select-selection--single {
      height: 50px;
      display: flex;
      align-items: center;


      .ant-select-selection__rendered {
        width: 100%;
        overflow: hidden;
      }
    }

    .ant-select-selection-search {
      input {
        box-shadow: unset !important;
      }
    }

  }

  .tree-select-wrapper {
    width: 100%;
    position: relative;
  }

  .ant-select-dropdown {
    .child-option {
      padding-left: 25px;
    }
  }

  .ant-select-arrow {
    height: 100%;
    top: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .custom-suffix-icon {
      width: 23px;
      height: 23px;
      transition: transform 0.2s;
      transform: rotate(90deg);

      path {
        fill: $inputBorderColor;
      }

      &.rotate {
        transform: rotate(270deg);
      }
    }
  }

  .visibility-icon {
    position: absolute;
    top: 18px;
    right: 14px;
    height: 18px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;
    }

    i {
      font-size: 16px;
      user-select: none;
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #10233c;
    }
  }
}

.select-options {
  width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 50;

  .option-item {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    overflow: hidden;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;

    input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #e6f7ff;
    }

    &.checked {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 600;
      background-color: #fafafa;
    }
  }
}

@media screen and (max-width: 450px) {
  .custom-input-group {
    margin-bottom: 10px;
  }
}

@import "../mainVariables";
@import "../mixins";

.blog-page-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  min-height: calc(100vh - 80px);
  padding-bottom: 80px;
  background-color: $greyBgColor;

  .blog-list {
    //padding: 0 $mainPadding;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;

    .blog-card {
      width: calc(96% / 5);
      margin-bottom: 1%;
      background-color: white;
      transition: all 0.5s;
      margin-right: 1%;
      border-radius: $borderRadius;
      cursor: pointer;

      .image-wrapper {
        width: 100%;
        position: relative;

        .image-proportion {
          padding-bottom: 68%;
          position: relative;
          width: 100%;

          img {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
            z-index: 1;
            border-radius: $borderRadius $borderRadius 0 0;
          }
        }
      }

      .item-info {
        width: 100%;
        padding: 20px;
        border-radius: $borderRadius;
        //transition: all 0.5s;

        .category {
          color: $brandColor;
          font-size: 13px;
          font-family: Medium;
          margin-bottom: 8px;
          text-transform: uppercase;
        }

        .title {
          font-size: 16px;
          line-height: 24px;
          height: 46px;
          color: black;
          font-family: Medium;
          overflow: hidden;
          //transition: all 0.3s;
        }
      }

      .bottom-part {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;

        .date {
          color: #6F788B;
          font-size: 12px;
          font-family: Medium;
        }

        .details-btn {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background-color: $brandColor;
          @include flex-center;
          transition: all .3s;
          cursor: pointer;
          font-size: 12px;
          overflow: hidden;

          .more{
            visibility: hidden;
            width: 0;
            color: white;
            //transition: all .3s;
            text-align: center;
            white-space: nowrap;
          }

          svg {
            transition: all .3s;
            visibility: visible;
            polygon {
              fill: white;
            }
          }

          &:hover {
            border-radius: 50px;
            width: 70px;
            svg{
              width: 0;
              visibility: hidden;
            }
            .more{
              width: 100%;
              visibility: visible;
            }
          }
        }
      }

      &:nth-child(5n) {
        margin-right: 0;
      }

      &:hover{
        box-shadow: 2px 0px 10px 0px rgba(113, 113, 113, 0.14);
        .bottom-part {
                 .details-btn {
            border-radius: 50px;
            width: 70px;
            svg{
              width: 0;
              visibility: hidden;
            }
            .more{
              width: 100%;
              visibility: visible;
            }
          }
        }
      }
    }

  }

  .ant-tabs {
    padding: 0 $mainPadding;
    margin-top: 60px;

    .ant-tabs-nav:before {
      border-bottom: 2px solid #E3E3E5;
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          font-size: 18px;
          font-family: Medium;

          &.ant-tabs-tab-active {
            border-color: $brandColor;

            &:before {
              border-color: $brandColor;
            }

            .ant-tabs-tab-btn {
              color: $brandColor;
            }
          }
        }
      }
    }

    .ant-tabs-content-holder{
      min-height: 440px;
    }
  }

  @media screen and (max-width: 1700px) {
    .blog-list {
      .blog-card {
        width: calc(97% / 4);

        &:nth-child(5n) {
          margin-right: 1%;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .blog-list {
      .blog-card {
        .item-info {
          padding: 14px;

          .title {
            font-size: 14px;
            line-height: 20px;
            height: 38px;
          }
        }

        .bottom-part {
          height: 50px;
          padding: 0 14px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .blog-list {
      .blog-card {
        width: calc(98% / 3);
        margin-bottom: 1%;

        &:nth-child(4n) {
          margin-right: 1%;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .ant-tabs {
      margin-top: 30px;

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .blog-list {
      .blog-card {

        .item-info {
          padding: 10px;

          .category {
            font-size: 12px;
            margin-bottom: 4px;
          }

          .title {
            font-size: 13px;
            line-height: 18px;
            height: 36px;
          }
        }

        .bottom-part {
          height: 40px;

          .date {
            font-size: 11px;
          }

          .details-btn {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .blog-list {
      .blog-card {
        width: calc(97% / 2);
        margin-bottom: 2%;
        margin-right: 2%;

        &:nth-child(3n) {
          margin-right: 2%;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    .blog-list {
      .blog-card {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        .bottom-part {
          height: 50px;
        }
      }
    }
  }
}
.singleTeam-page {
    @media screen and (max-width:800px){
        flex-direction: column;
        padding: 60px 10px;
    }

    padding: 60px 100px;
    display: flex;
    gap: 30px;
    align-items: flex-start;

    .singleTeam-left {
        display: flex;
        align-items: flex-end;
        gap: 10px;
        flex: 0.5;

        @media screen and (max-width:800px){
            flex-direction: column;
        }

        .img {
            height: 616px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .icons {
            display: flex;
            flex-direction: column;
            height: 100%;

            @media screen and (max-width:800px){
                flex-direction: row;
                gap: 10px;
            }
        }
    }

    .singleTeam-right {
        flex: 0.5;

        h3 {
            font-weight: 600;
            color: black;
            font-size: 16px;
        }

        h1 {
            font-weight: 700;
            color: black;
            font-size: 24px;
            margin: 30px 0;
        }

        p {
            font-weight: 500;
            font-size: 14px;
        }
    }
}
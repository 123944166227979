@import "../mainVariables";
@import "../mixins";

.privacy-policy-wrapper {
  padding: 20px $mainPadding;

  .privacy-policy-title {
    margin: 30px 0;
    @include font(Medium, 22);
    text-align: center;
  }
}

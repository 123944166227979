@import "../mainVariables";
@import "../mixins";

.clients-page-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  min-height: calc(100vh - 80px);
  background-color: white;
  padding-bottom: 80px;

  .clients-list {
    padding: 0 $mainPadding;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;

    .client-card {
      width: calc(96% / 5);
      margin-bottom: 1%;
      background-color: #F3F4F6;
      border-radius: 15px;
      transition: all 0.5s;
      margin-right: 1%;

      .card-proportion {
        width: 100%;
        padding-bottom: 95%;
        position: relative;

        .inner-part {
          padding: 20px;
          height: 100%;
          width: 100%;
          position: absolute;
          bottom: 0;
          //right: 0;
          left: 0;
          object-fit: cover;
          border-radius: $borderRadius;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .image-wrapper {
        width: 100%;
        position: relative;
        //padding: 0 30px;
        padding-bottom: 38.4%;
        margin: auto;
        //height: 70px;
        //margin: 10px 0 40px 0;
        //transition: all 0.5s;

        img {
          transition: all 0.3s;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .fade-image {
          z-index: 1;
          opacity: 1;
        }
        .main-image {
          z-index: 0;
          opacity: 0;
        }
      }

      &:nth-child(5n) {
        margin-right: 0;
      }

      .item-info {
        width: 100%;
        background-color: white;
        padding: 16px;
        border-radius: $borderRadius;
        transition: all 0.5s;

        .title {
          font-size: 18px;
          color: black;
          font-family: Medium;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 6px;
          //transition: all 0.3s;
        }

        .description {
          font-size: 13px;
          line-height: 20px;
          color: #737781;
          overflow: hidden;
          height: 38px;
          //transition: all 0.3s;
          position: relative;

          a {
            color: white;
            font-size: 12px;
            align-items: center;
            width: 100%;
            display: none;
            justify-content: flex-end;
            position: absolute;
            bottom: 0;
            left: 0;

            span {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              display: block;
            }

            svg {
              height: 18px;

              polygon {
                fill: white;
              }
            }
          }
        }
      }

      &:hover {

        .image-wrapper {
          .fade-image {
            z-index: 0;
            opacity: 0;
          }
          .main-image {
            z-index: 1;
            opacity: 1;
          }

        }

        .item-info {
          background-color: $brandColor;

          .title {
            color: white;
          }


          .description {
            .text {
              display: none;
            }

            a {
              display: flex;
            }
          }
        }
      }
    }

  }

  @media screen and (max-width: 1700px) {
    .clients-list {
      .client-card {
        width: calc(97% / 4);

        &:nth-child(5n) {
          margin-right: 1%;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .clients-list {

      .client-card {

        .card-proportion {

          .inner-part {
            padding: 20px;
          }
        }

        .item-info {
          padding: 14px;

          .title {
            font-size: 16px;
            margin-bottom: 8px;
          }

          .description {
            font-size: 13px;
            line-height: 20px;
            color: #737781;
            overflow: hidden;
            height: 38px;
            //transition: all 0.3s;
            position: relative;

            a {
              color: white;
              font-size: 12px;
              align-items: center;
              width: 100%;
              display: none;
              justify-content: flex-end;
              position: absolute;
              bottom: 0;
              left: 0;

              span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
              }

              svg {
                height: 18px;

                polygon {
                  fill: white;
                }
              }
            }
          }
        }

        &:hover {

          .image-wrapper {

            img {
              filter: unset;
            }
          }

          .item-info {
            background-color: $brandColor;

            .title {
              color: white;
            }


            .description {
              .text {
                display: none;
              }

              a {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .clients-list {
      .client-card {
        width: calc(98% / 3);
        margin-bottom: 1%;

        &:nth-child(4n) {
          margin-right: 1%;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .clients-list {
      .client-card {
        width: calc(97% / 2);
        margin-bottom: 2%;
        margin-right: 2%;

        &:nth-child(3n) {
          margin-right: 2%;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        .card-proportion {

          .inner-part {
            padding: 14px;
          }
        }

        .item-info {
          padding: 10px;

          .title {
            font-size: 14px;
            margin-bottom: 8px;
          }

          .description {
            font-size: 11px;
            line-height: 16px;
            height: 30px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .clients-list {
      .client-card {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        .card-proportion {

          .inner-part {
            padding: 14px;
          }
        }

        .item-info {
          padding: 14px;

          .title {
            font-size: 16px;
            margin-bottom: 8px;
          }

          .description {
            font-size: 12px;
            line-height: 18px;
            height: 34px;
          }
        }
      }
    }
  }
}
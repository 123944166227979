@import "../mainVariables";
@import "../mixins";

.services-wrapper {
  width: 100%;
  padding: 0 $mainPadding 0;
  margin-bottom: 40px;
  position: relative;

  .services-title {
    text-align: center;
    font-size: 42px;
    font-family: Medium;
    margin: 100px 0;
  }

  .services-list-wrapper {
    position: relative;
    margin-right: -20px;

    .slick-track {
      margin-left: unset;
    }

    .service-card {
      width: 25%;
      position: relative;
      transition: all 0.3s;
      cursor: pointer;
      overflow: hidden;
      padding-bottom: 20px;
      padding-right: 20px;

      .image-wrapper {
        width: 100%;

        .image-proportion {
          padding-bottom: 71.4%;
          position: relative;
          width: 100%;

          img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            object-fit: cover;
            height: 100%;
            border-radius: 8px;
          }
        }
      }

      .title-wrapper {
        width: 80%;
        background-color: white;
        border-radius: 8px;
        transform: translate(calc(10% + 5px), -50%);
        box-shadow: 2px 0 10px 0 rgba(113, 113, 113, 0.14);
        @include flex-center;
        transition: all 0.2s;
        padding: 20px;

        .service-title {
          text-align: center;
          width: 100%;
          font-size: 18px;
          line-height: 24px;
          height: 48px;
          overflow: hidden;

          &::first-line {
            word-spacing: 100vw;
          }
        }

        &:hover {
          background-color: $brandColor;

          .service-title {
            color: white;
          }
        }
      }
    }


    .next-btn, .prev-btn {
      top: 38%;
      @media all and (max-width: 1600px) {
        top: 33%;
      }
    }

    .next-btn {
      right: 20px;
    }
  }

  @media all and (max-width: 1300px) {

    .services-list-wrapper {

      .service-card {

        .title-wrapper {

          .service-title {
            font-size: 16px;
            line-height: 22px;
            height: 44px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    position: relative;
    padding-right: 20px;
    .services-title {
      font-size: 28px;
      margin: 60px 0;
      text-align: unset;
    }
    .services-list-wrapper {
      position: unset;

      .service-card {

        .title-wrapper {


        }
      }

      .next-btn, .prev-btn {
        top: 0;
        transform: unset;
      }

      .next-btn {
        right: $mainPadding;
      }

      .prev-btn {
        right: calc(#{$mainPadding} + 55px);
        left: unset;
      }
    }
  }

  @media all and (max-width: 800px) {
    padding-right: 0;
    .services-title {
      font-size: 22px;
    }
    .services-list-wrapper {
      margin-right: 0;

      .service-card {

        .title-wrapper {

          .service-title {
            font-size: 12px;
            line-height: 20px;
            height: 36px;
          }
        }
      }
      .prev-btn {
        right: calc(#{$mainPadding} + 45px);
      }
    }
  }
}

@import "../mainVariables";
@import "../mixins";

.custom-page-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  padding: 20px $mainPadding;

  .custom-page-title {
    @include font(Medium, 34);
    color: black;
    padding-bottom: 10px;
    border-bottom: 1px solid $greyBorderColor;
  }

  .custom-page-content {
    display: flex;
    justify-content: space-between;

    .left-part {
      width: 42%;
      padding: 50px 50px 50px 0;
      position: relative;

      .custom-page-img {
        border-radius: 8px;
        width: 100%;
      }
    }

    .right-part {
      width: 56%;
      padding: 40px 40px 0;
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
    }
  }

  @media screen and (max-width: 1400px) {
    .custom-page-content {
      .left-part {
        width: 55%;
        padding: 40px 30px 30px 0;
      }

      .right-part {
        padding: 30px 16px 0;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .custom-page-content {
      flex-direction: column;

      .left-part {
        width: 100%;
      }

      .right-part {
        width: 100%;
        padding: 40px 0 0;
      }
    }
  }
}
@import "../mainVariables";
@import "../mixins";

.contacts-wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 28%;

  .contacts-proportion {
    background-color: #06191D;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bg-image{
      width: 48%;
      height: 100%;
      object-fit: contain;
      object-position: bottom;
    }

    .form-content{
      width: 44%;
      color: white;
      padding-right: $mainPadding;

      .title{
        font-size: 40px;
        font-family: Medium;
        //font-weight: 600;
        overflow: hidden;
        height: 106px;
        margin-bottom: 30px;
        line-height: 56px;
      }

      .description{
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 40px;
      }

      .input-wrapper{
        display: flex;
        align-items: center;
        padding: 0 10px;
        width: 100%;
        height: 60px;
        border-radius: 60px;
        background-color: #354548;

        input{
          width: 100%;
          background-color: transparent;
          color: white;
          padding-left: 16px;
          &::placeholder{
            color: white;
          }
        }
        .error{
          color: red;
        }
        .sent-email{
          width: 160px;
          height: 44px;
          border-radius: 44px;
          background-color: $brandColor;
          color: white;
          font-size: 14px;
          font-family: Medium;
          transition: all 0.2s;
          @include flex-center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .tick-icon{
            width: 0;
            height: 14px;
            transition: all 0.2s;
            visibility: visible;
            path{
              fill: white;
            }
          }
          .show-tick{
            width: 14px;
            visibility: visible;
            opacity: 1;
            margin-left: 10px;
          }
          .hide-tick{
            width: 0;
            visibility: hidden;
            opacity: 0;
            //width: 0;
          }

          &:hover {
            color: $brandColor;
            background-color: white;

            svg {
              poligon {
                fill: white;
              }
            }
            .tick-icon{
              path{
                fill: $brandColor;
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1300px) {
    .contacts-proportion {
      .form-content{
        width: 44%;
        color: white;
        padding-right: $mainPadding;

        .title{
          font-size: 32px;
          height: 86px;
          margin-bottom: 20px;
          line-height: 46px;
        }

        .description{
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 30px;
        }

        .input-wrapper{
          padding: 0 6px;
          width: 100%;
          height: 50px;

          input{
            width: 100%;
            background-color: transparent;
            color: white;
            padding-left: 12px;
            &::placeholder{
              color: white;
            }
          }
          .sent-email{
            width: 140px;
            height: 40px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    padding-bottom: 32%;
    .contacts-proportion {

      .bg-image{
        width: 56%;
        height: 100%;
        object-fit: contain;
        transform: translateX(-26%);
      }

      .form-content{
        width: 64%;

        .title{
          font-size: 24px;
          height: 64px;
          margin-bottom: 16px;
          line-height: 34px;
        }
        .description{
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 20px;
        }

        .input-wrapper{
          padding: 0 4px;
          height: 44px;

          .sent-email{
            width: 130px;
            height: 38px;
          }
        }
      }
    }
  }

  @media all and (max-width: 700px) {
    padding-bottom: 70%;
    .contacts-proportion {

      .bg-image{
       display: none;
      }

      .form-content{
        width: 100%;
        padding: 0 $mainPadding;

        .title{
          font-size: 16px;
          height: 44px;
          margin-bottom: 16px;
          line-height: 24px;
        }
        .description{
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 20px;
        }

        .input-wrapper{
          padding: 0 6px;
          height: 48px;

          .sent-email{
            width: 130px;
            height: 38px;
          }
        }
      }
    }
  }
}

@import "../mainVariables";
@import "../mixins";

.team-page-wrapper {
  -webkit-user-select: none !important;
  user-select: none !important;
  min-height: calc(100vh - 80px);
  background-color: $greyBgColor;

  .team-list-wrapped {
    margin-top: 100px;
    padding: 0 $mainPadding;
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  .team-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .team-card {
      width: 49%;
      display: flex;
      position: relative;
      margin-bottom: 80px;

      .image-wrapper {
        width: 40%;
        position: relative;

        .image-proportion {
          padding-bottom: 100%;
          position: relative;
          width: 100%;

          img {
            position: absolute;
            bottom: 30px;
            left: 30px;
            width: 100%;
            object-fit: cover;
            height: 100%;
            border-radius: 15px;
            z-index: 1;
          }

          .linkedin-link {
            visibility: hidden;
            position: absolute;
            bottom: 40px;
            right: -20px;
            z-index: 1;
          }
        }
      }

      .item-info {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: flex-end;
        padding: 30px;
        border-radius: 15px;
        transition: all 0.2s;

        .inner-part {
          width: 60%;
          padding-left: 60px;

          .position {
            font-size: 13px;
            height: 40px;
            color: $brandColor;
            margin-bottom: 4px;
            font-family: Medium;
            letter-spacing: 0.6px;
            overflow: hidden;
            //white-space: nowrap;
            //text-overflow: ellipsis;
          }

          .name {
            font-size: 20px;
            color: black;
            font-family: Bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .team-card__link {
            font-size: 14px;
            color: black;
            font-family: Bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .line {
            background-color: $brandColor;
            height: 3px;
            width: 50%;
            border-radius: 3px;
            margin: 26px 0;
          }

          .description {
            font-size: 14px;
            line-height: 20px;
            color: #737781;
            overflow: hidden;
            height: 58px;
          }
        }
      }

      &:hover {
        .image-wrapper {
          .image-proportion {
            .linkedin-link {
              visibility: visible;
            }
          }
        }

        .item-info {
          background-color: $brandColor;

          .inner-part {
            .social__links {
              .icon__item {
                a {
                  svg {
                    g {
                      path {
                        fill: white !important;
                      }
                    }
                  }
                }
              }

              .icons__all {
                .text {
                  a {
                    svg {
                      g {
                        path {
                          fill: white !important;
                        }
                      }
                    }
                  }
                }
              }
            }

            .position {
              color: white;
            }

            .name {
              color: white;
            }

            .team-card__link {
              color: white;
              font-weight: 400 !important;

              &:hover {
                color: rgb(212, 212, 212);
                transition: 300ms ease;
              }
            }

            .line {
              background-color: white;
            }

            .description {
              color: white;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1700px) {
    .team-list {
      justify-content: unset;

      .team-card {
        width: 32%;

        &:not(:nth-child(3n)) {
          margin-right: 1%;
        }
      }
    }
  }

  @media screen and (max-width: 2200px) and (min-width: 1700px) {
    .team-list {
      .team-card {
        width: 32.5%;

        .item-info {
          .inner-part {
            padding-left: 50px;

            .position {
              font-size: 12px;
              height: 36px;
            }

            .name {
              font-size: 16px;
            }

            .line {
              margin: 10px 0;
            }

            .description {
              font-size: 12px;
              line-height: 18px;
              height: 52px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .team-list {
      .team-card {
        .item-info {
          .inner-part {
            .position {
              font-size: 12px;
              height: 36px;
            }

            .name {
              font-size: 16px;
            }

            .line {
              margin: 10px 0;
            }

            .description {
              font-size: 12px;
              line-height: 18px;
              height: 52px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .team-list {
      .team-card {
        .image-wrapper {
          .image-proportion {
            img {
              bottom: 20px;
              left: 20px;
            }
          }
        }

        .item-info {
          padding: 20px;

          .inner-part {
            padding-left: 50px;

            .position {
              //font-size: 11px;
            }

            .name {
              font-size: 16px;
            }

            .line {
              margin: 10px 0;
            }

            .description {
              font-size: 12px;
              line-height: 18px;
              //height: 34px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 980px) {
    .team-list {
      .team-card {
        width: 100%;

        .image-wrapper {
          width: 40%;
          position: relative;

          .image-proportion {
            padding-bottom: 100%;
            position: relative;
            width: 100%;

            img {
              bottom: 40px;
              left: 40px;

              .linkedin-link {
                visibility: visible;
                bottom: 20px;
                right: -20px;
              }
            }
          }
        }

        .item-info {
          padding: 40px;

          .inner-part {
            width: 60%;
            padding-left: 70px;

            .position {
              font-size: 14px;
              height: 42px;
              margin-bottom: 8px;
            }

            .name {
              font-size: 24px;
            }

            .line {
              margin: 32px 0;
            }

            .description {
              font-size: 14px;
              line-height: 22px;
              height: 62px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .team-list {
      .team-card {
        .image-wrapper {
          .image-proportion {
            img {
              bottom: 30px;
              left: 30px;
            }
          }
        }

        .item-info {
          padding: 30px;

          .inner-part {
            padding-left: 50px;

            .position {
              font-size: 12px;
              height: 36px;
              margin-bottom: 6px;
            }

            .name {
              font-size: 18px;
            }

            .line {
              margin: 13px 0;
            }

            .description {
              font-size: 13px;
              line-height: 20px;
              height: 58px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .team-list {
      .team-card {
        flex-direction: column;
        background-color: white;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 3px 23px 0px rgba(38, 37, 39, 0.2);
        //padding: 30px;
        border-radius: 15px;
        margin-bottom: 120px;

        .image-wrapper {
          width: 70%;

          .image-proportion {
            img {
              bottom: 60px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        .item-info {
          padding: 0 20px 0 20px;
          flex-direction: column;
          position: unset;
          box-shadow: unset;
          background-color: unset;

          .inner-part {
            width: 100%;
            padding-left: 0;
            text-align: center;
            transform: translateY(-30px);

            .position {
              margin-bottom: 6px;
            }

            .name {
              font-size: 18px;
            }

            .line {
              margin: 13px auto;
            }

            .description {
              font-size: 13px;
              line-height: 20px;
              //height: 38px;
            }
          }

          &:hover {
            background-color: unset;

            .inner-part {
              .position {
                color: $brandColor;
              }

              .name {
                color: unset;
              }

              .line {
                background-color: $brandColor;
              }

              .description {
                color: #737781;
              }
            }
          }
        }
      }
    }
  }
}

.social__links {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 30px;
  margin-top: 10px;

  .icon__item {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .icons__all {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;

    .text {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      z-index: 1;

      a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .icons__hidden1 {
      position: absolute;
      opacity: 0.0;

      a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .icons__hidden2 {
      position: absolute;
      opacity: 0.0;

      a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:hover {
      .text {
        transition: 300ms;
        position: relative;
      }

      .icons__hidden1 {
        transition: 300ms;
        opacity: 1;
        position: relative;
      }

      .icons__hidden2 {
        transition: 300ms;
        opacity: 1;
        position: relative;
      }
    }
  }
}
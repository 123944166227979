@import "../mainVariables";
@import "../mixins";

.layout-wrapper {
  min-height: 100vh;
  width: 100%;
  position: relative;
  .main-content {
    min-height: calc(100vh - 80px);
    //min-height: 100vh;
  }
  .homepage-wrapper {
    -webkit-user-select: none !important;
    user-select: none !important;
    background-color: $greyBgColor;
  }
  .callBtn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: fixed;
    bottom: 42px;
    right: 40px;
    z-index: 1013;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5b8787;
    box-shadow: 0 0 20px 0 #3d5353;
    svg {
      width: 30px;
      height: 30px;
    }
    &::after {
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      height: 100%;
      width: 100%;
      border: 20px solid #5b8787;
      border-radius: 100%;
      animation-name: ripple;
      animation-duration: 3s;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
      z-index: -1;
    }

    &::before {
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      height: 100%;
      width: 100%;
      border: 20px solid #5b8787;
      border-radius: 100%;
      animation-name: ripple;
      animation-duration: 3s;
      animation-delay: 0.5s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
      z-index: -1;
    }
  }
  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale3d(0.85, 0.85, 1);
    }

    to {
      opacity: 0;
      transform: scale3d(1.5, 1.5, 1);
    }
  }
}

@media all and (max-width: 1000px) {
  .layout-wrapper {
    .main-content {
      //padding-top: 70px;
    }
  }
}

@media all and (max-width: 600px) {
  .layout-wrapper {
    .main-content {
      //padding-top: 60px;
    }
  }
}

@import "../mainVariables";
@import "../mixins";

.blog-details-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  min-height: calc(100vh - 80px);
  padding: 80px calc(2.5 * #{$mainPadding});
  background-color: $greyBgColor;

  .image-wrapper {
    width: 70%;
    margin: auto;
    position: relative;

    .image-proportion {
      padding-bottom: 68%;
      position: relative;
      width: 100%;

      img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        height: 100%;
        z-index: 1;
        border-radius: $borderRadius $borderRadius 0 0;
      }
    }
  }

  .date-box {
    width: 70%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #dddddd;
    margin: 30px  auto;

    .category {
      color: $brandColor;
      font-size: 16px;
      font-family: Medium;
    }

    .date {
      color: #6F788B;
      font-size: 16px;
      font-family: Medium;
    }
  }

  .details-title {
    font-size: 52px;
    color: black;
    font-family: Medium;
    width: 70%;
    margin: auto;
    margin-bottom: 50px;
  }
  .details-description{
    width: 70%;
    margin: auto;
    font-size: 16px;
  }

  @media screen and (max-width: 1700px) {
    .details-title {
      font-size: 46px;
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 1050px) {
    padding: 40px $mainPadding;
    .date-box {
      height: 60px;
      margin: 20px auto;

      .category {
        font-size: 14px;
      }

      .date {
        font-size: 14px;
      }
    }
    .details-title {
      font-size: 38px;
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 20px $mainPadding;
    .image-wrapper {
      width: 100%;
    }
    .date-box {
      width: 100%;
    }
    .details-title {
      width: 100%;
      font-size: 24px;
      margin-bottom: 24px;
    }
    .details-description{
      width: 100%;
    }
  }
}
@import "../mainVariables";
@import "../mixins";

.sphere-wrapper {
  width: 100%;
  padding: 0 $mainPadding;
  background-color: #06191D;
  margin-bottom: 100px;

  .sphere-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 100px 0;

    .title {
      font-size: 40px;
      font-family: Medium;
    }

    .see-more {
      width: 180px;
      height: 50px;
      border-radius: 50px;
      background-color: $brandColor;
      @include flex-center;
      font-family: Medium;
      font-size: 16px;
      transition: all 0.2s;

      &:hover {
        color: $brandColor;
        background-color: white;

        svg {
          poligon {
            fill: white;
          }
        }
      }
    }
  }

  .sphere-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;

    .sphere-card {
      width: 24%;
      position: relative;

      .card-proportion {
        padding-bottom: 145%;
        position: relative;
        width: 100%;

        img {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          object-fit: cover;
          height: 100%;
          border-radius: $borderRadius;
        }
      }

      .sphere-title {
        position: absolute;
        bottom: 0;
        color: white;
        font-size: 18px;
        height: 108px;
        overflow: hidden;
        font-family: Medium;
        z-index: 1;
        padding: 30px;
        width: 100%;
        text-align: center;
        border-radius: 0 0 $borderRadius $borderRadius;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }

  @media all and (max-width: 1050px) {
    padding-bottom: 50px;
    padding-right: 0;
    position: relative;
    .sphere-header {
      padding: 60px 0;
      justify-content: center;

      .title {
        font-size: 28px;
        @media all and (max-width: 500px){
          font-size: 22px;
        }
      }

      .see-more {
        width: 180px;
        height: 50px;
        border-radius: 50px;
        background-color: $brandColor;
        @include flex-center;
        font-size: 16px;
        position: absolute;
        bottom: 50px;
        @media all and (max-width: 700px){
          height: 38px;
          width: 150px;
          font-size: 12px;
        }
      }
    }

    .sphere-list {
      max-width: 100%;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      overflow-scrolling: touch;
      scroll-padding: 10px;

      &::-webkit-scrollbar {
        height: 0;
        display: none;
      }

      .sphere-card {
        width: 60px;
        flex: 0 0 280px;
        scroll-snap-align: start;
        margin-right: 20px;
      }
    }
  }
}
